import {
  readonly, Ref, ref, watch,
} from 'vue';
import { trackGet } from '@/services/api/tracks';
import { CourseTreeNodeType, ICourse, ICourseStepState } from '../types';
import { parseCourseTree } from '../utils';
import { ITree, treeMap } from '@/common/tree';
import { AtomType } from '@/domains/atom';
import { AttemptStatus, PlayerSessionStatus } from '@/domains/common';

interface useCourseProviderProps {
  id: Ref<ICourse['id'] | null>;
}

export const useCourseProvider = ({ id }: useCourseProviderProps) => {
  const course = ref<ICourse | null>(null);
  const steps = ref<ITree<ICourseStepState>>({
    current: {
      id: '',
      catalogueAtomId: '',
      atomContentId: '',
      atomType: AtomType.MULTICONTENT,
      name: '',
      description: '',
      duration: 0,
      source: '',
      authorId: '',
      ownerId: '',
      licenseType: '',
      stepSessionStatus: PlayerSessionStatus.UNKNOWN,
      stepSessionId: 0,
      playerSessionId: 0,
      isActive: false,
      isDisabled: false,
      isCompleted: false,
      isFailed: false,
      isWasted: false,
      itemType: CourseTreeNodeType.STEP,
      attemptStatus: AttemptStatus.PASSED,
    },
    parent: null,
    children: [],
  });
  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);

  // TODO: создать useFetch для запросов в composables
  const fetch = async ({ courseId }: { courseId: ICourse['id'] }) => {
    isLoading.value = true;

    try {
      const response = await trackGet({ id: courseId });

      course.value = response.track;

      steps.value = treeMap(
        parseCourseTree(
          response.order,
          [...response.steps, ...response.sections],
          { linearFlow: course.value.linearFlow },
        ),
      );

      isError.value = false;
    } catch (e: any) {
      isError.value = true;
    } finally {
      isLoading.value = false;
    }
  };

  watch([id], () => {
    if (!id.value) return;

    fetch({ courseId: id.value });
  });

  return {
    isLoading: readonly(isLoading),
    isError: readonly(isError),
    course: readonly(course),
    steps,
    fetch,
  };
};
