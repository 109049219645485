import { AtomType } from '@/domains/atom';
import Names from '@/plugins/vue-router/routeNames';

const dict: Record<AtomType, string> = {
  [AtomType.MULTILEVELTEST]: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_VIEW,
  [AtomType.MULTICONTENT]: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTICONTENT,
  [AtomType.QUIZ]: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ,
  [AtomType.SCORM]: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_SCORM_VIEW,
  [AtomType.TRACK]: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_UNKNOWN,
  [AtomType.PROGRAM]: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_UNKNOWN,
  [AtomType.UNKNOWN]: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_UNKNOWN,
};

/**
 * @deprecated удалить после перевода курса на новый флоу
 */
export const getRouteNameForAtomType = (atomType: AtomType) => dict[atomType];
