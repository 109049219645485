import { make } from './make';
import { INodeCurrent, ITree, ITreeJson } from './types';

export const parse = <
  T extends INodeCurrent
>(json: ITreeJson<T>, parent: ITree<T> | null = null): ITree<T> => {
  const children = json.children ?? [];

  const tree = make(
    json.current,
    parent,
    [],
  );

  tree.children = children.map(
    (child) => parse(child, tree),
  );

  return tree;
};
