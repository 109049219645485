import { dfs } from './dfs';
import { INodeCurrent, ITree } from './types';

export const first = <T extends INodeCurrent>(
  tree: ITree<T> | undefined,
  predicate: (node: T) => boolean,
): ITree<T> | undefined => {
  if (!tree) return undefined;

  let firstNode: ITree<T> | undefined;

  dfs(tree, (node: ITree<T>) => {
    if (predicate(node.current)) {
      firstNode = node;
      return false;
    }

    return true;
  });

  return firstNode;
};
