
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CourseSectionProgress',
  props: {
    value: {
      type: Number, // Note: номер секции по порядку
      default: 0,
    },

    progress: {
      type: Number, // Note: от 0 до 100
      default: 0,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
