import { parse } from '@/common/tree/parse';
import { ICourseHierarchy, ICourseItem } from '../types';
import { buildCourseStepsTree } from './buildCourseStepsTree';

interface IOptions {
  linearFlow: boolean;
  level: number;
}

const DEFAULT_OPTIONS = {
  linearFlow: false,
  level: 1,
};

export const parseCourseTree = <T extends ICourseItem>(
  courseSteps: ICourseHierarchy[],
  dictionary: T[],
  userOptions: Partial<IOptions>,
) => {
  const options = { ...DEFAULT_OPTIONS, ...userOptions };

  const tree = parse(
    {
      current: {} as any,
      children: courseSteps.map(
        (o) => buildCourseStepsTree(
          o,
          dictionary,
          options,
        ),
      ),
    },
    null,
  );

  return tree;
};
