import { ITree } from '@/common/tree';
import {
  ICourseSectionState, ICourseStepState, CourseTreeNodeType,
} from '../types';

export const isCourseStepSectionNode = <
  Children extends ICourseStepState,
  Parent extends ICourseStepState
>(node: ITree<ICourseStepState, Children, Parent>): node is ITree<ICourseSectionState, Children, Parent> => {
  return node.current.itemType === CourseTreeNodeType.SECTION;
};
