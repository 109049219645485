import { INodeCurrent, ITree } from './types';

const identity = (x: any) => x;

export const treeMap = <
  Root extends INodeCurrent,
  Children extends INodeCurrent = Root,
  Parent extends INodeCurrent = Root,
  ReturnRoot extends INodeCurrent = Root
>(
    { current, parent, children }: ITree<Root, Children, Parent>,
    mapper: (node: Root) => ReturnRoot = identity,
  ): ITree<ReturnRoot, ReturnRoot, Parent> => {
  return {
    current: mapper(current),
    parent,
    children: children.map((child) => treeMap<any>(child, mapper)),
  };
};
