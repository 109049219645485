import {
  computed, readonly, Ref, ref, watch,
} from 'vue';
import VueRouter from 'vue-router';
import { trackCurrentStepGet, trackSessionGet, trackStepRun } from '@/services/api/tracks';
import { PlayerSessionStatus, UUID } from '@/domains/common';
import { findBy } from '@/common/tree/findBy';
import { nextSibling } from '@/common/tree/nextSibling';
import { prevSibling } from '@/common/tree/prevSibling';
import {
  ICourse, ICourseAssignment, ICourseSession, ICourseStepState,
} from '../types';
import { isCourseStepAtom, parseCourseTree } from '../utils';
import { TrackSessionStatus } from '@/domains/track';
import { getFirstLeaf, isDepth, ITree } from '@/common/tree';
import { getRouteNameForAtomType } from '@/helpers/getRouteNameForAtomType';
import { dfs } from '@/common/tree/dfs';
import { isParentOf } from '@/common/tree/isParentOf';
import { Names } from '@/plugins/vue-router';
import { sleep } from '@/helpers';
import { AtomType } from '@/domains/atom';

interface useCoursePlayerProviderProps {
  trackSessionId: Ref<number>;
  initialStepId: Ref<string | null>;
  router: VueRouter;
}

/**
 * @deprecated удалить после перевода курса на новый флоу.
 * Используй useTrackPlayer
 */
export const useCoursePlayerProvider = ({ trackSessionId, initialStepId, router }: useCoursePlayerProviderProps) => {
  const isLoading = ref<boolean>(true);
  const isCurrentStepLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);
  const course = ref<ICourse>();
  const steps = ref<ITree<ICourseStepState> | undefined>(undefined);
  const currentStepId = ref<UUID | null>(initialStepId.value);
  const courseSession = ref<ICourseSession>({
    trackSessionStatus: TrackSessionStatus.NEW,
    finishedStepsCount: 0,
    totalStepsCount: 0,
  });
  const assignment = ref<ICourseAssignment>({
    assignmentSessionId: 0,
    catalogueAtomId: '',
    startDate: '',
    finishDate: '',
  });

  watch(initialStepId, (newValue) => {
    currentStepId.value = newValue;
  });

  const currentStep = computed(() => findBy(steps.value, 'id', currentStepId.value));

  const nextStep = computed(() => nextSibling(currentStep.value, isCourseStepAtom));
  const prevStep = computed(() => prevSibling(currentStep.value, isCourseStepAtom));

  const courseCompletionRate = computed(() => (courseSession.value.totalStepsCount === 0
    ? 0
    : Math.round((courseSession.value.finishedStepsCount / courseSession.value.totalStepsCount) * 100)));
  const assignmentFinishData = computed(() => assignment.value.finishDate);
  const isCurrentStepCompleted = computed(
    () => currentStep.value?.current
      && isCourseStepAtom(currentStep.value.current)
      && currentStep.value.current.stepSessionStatus === PlayerSessionStatus.COMPLETED,
  );

  const stepsState = computed(() => {
    let isDisabled = false;
    let isLastStepCompleted = false;
    let orderNumber = 0;

    dfs(steps.value, (node) => {
      if (isCourseStepAtom(node.current)) {
        const isActive = node.current.id === currentStepId.value;
        const isAvailable = node.current.stepSessionStatus === PlayerSessionStatus.ACTIVE;
        const isCompleted = node.current.stepSessionStatus === PlayerSessionStatus.COMPLETED
          && node.current.attemptStatus !== 'failed';
        const isFailed = node.current.attemptStatus === 'failed'
            && node.current.stepSessionStatus === PlayerSessionStatus.ACTIVE;
        const isWasted = node.current.attemptStatus === 'failed'
          && node.current.stepSessionStatus === PlayerSessionStatus.COMPLETED;

        // eslint-disable-next-line no-param-reassign
        node.current = {
          ...node.current,
          isActive,
          isDisabled,
          isCompleted,
          isFailed,
          isWasted,
        };

        if (course.value?.linearFlow && (isAvailable || isLastStepCompleted)) {
          isDisabled = !isCompleted && !isWasted;
        }

        isLastStepCompleted = isCompleted || isWasted;
      } else {
        if (isDepth(node, 1)) orderNumber += 1;

        // eslint-disable-next-line no-param-reassign
        node.current = {
          ...node.current,
          orderNumber,
          progress: (node.current.finishedStepsCount / node.current.totalStepsCount) * 100,
          isActive: currentStep.value ? isParentOf(node, currentStep.value) : false,
          isDisabled,
          isOpened: true,
        };
      }

      return true;
    });

    return steps.value;
  });

  const isLinearFlow = computed(() => course.value?.linearFlow);
  const isNextStepAvailable = computed(() => (isLinearFlow.value ? isCurrentStepCompleted.value : true));
  const isPrevStepAvailable = computed(() => Boolean(prevStep.value));

  const setCurrentStep = async (stepId: UUID, method: 'push' | 'replace' = 'push') => {
    const tree = findBy(stepsState.value, 'id', stepId);

    if (!tree) return;

    const { current: step } = tree;

    if (!isCourseStepAtom(step)) return;

    if (step.id === currentStepId.value) return;

    if (step.isDisabled) return;

    let { playerSessionId } = step;

    if (!playerSessionId) {
      const stepSession = await trackStepRun({
        trackSessionId: trackSessionId.value,
        stepId: step.id,
      });

      playerSessionId = stepSession.playerSessionId;
    }

    currentStepId.value = stepId;

    await router[method]({
      name: getRouteNameForAtomType(step.atomType),
      params: {
        trackSessionId: String(trackSessionId.value),
        stepId: step.id,
        playerSessionId: String(playerSessionId),
        // userId: String(this.userId),
      },
    });
  };

  // TODO: создать useFetch для запросов в composables
  const fetch = async () => {
    isLoading.value = true;
    isCurrentStepLoading.value = true;
    isError.value = false;

    try {
      const response = await trackSessionGet({ trackSessionId: trackSessionId.value });

      assignment.value = response.assignment;
      course.value = { ...course.value, ...response.track };
      courseSession.value = response.trackSession;

      /**
       * Подготовит дерево шагов добавив у ним parent для оптимизации поиска
       */
      // FIXME: Сейчас рут и только рут имеет current === null,
      // чтобы не добавлять лишних проверок игнорируем их
      steps.value = parseCourseTree(response.order, [...response.steps, ...response.sections], {
        linearFlow: response.track.linearFlow,
      });

      if (!steps.value) {
        console.error('Странно, но курс не имеет шагов');
        return;
      }

      /**
       * Если шаг не указан или он не верный нужно запросить активный шаг
       * */
      if (!currentStepId.value) {
        const { stepId } = await trackCurrentStepGet({
          trackSessionId: trackSessionId.value,
        });

        /**
         * Если нет ни одного активного шага нет, стартуем первый
         * */
        if (!stepId) {
          const firstLeaf = getFirstLeaf(steps.value);

          if (!firstLeaf) {
            return;
          }

          await trackStepRun({
            trackSessionId: trackSessionId.value,
            stepId: firstLeaf?.current.id,
          });

          setCurrentStep(firstLeaf?.current.id, 'replace');

          console.error('Странно, но курс не имеет шагов');
        } else {
          setCurrentStep(stepId, 'replace');
        }
      }
    } catch (e: unknown) {
      isError.value = true;
    } finally {
      isLoading.value = false;
      isCurrentStepLoading.value = false;
    }
  };

  const onStepNextHandler = async () => {
    if (!nextStep || !nextStep.value) {
      await router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
        params: {
          playerSessionId: String(trackSessionId.value),
          atomType: AtomType.TRACK,
          trackSessionId: String(trackSessionId.value),
        },
      });

      return;
    }

    await setCurrentStep(nextStep.value.current.id);
  };

  const onStepPrevHandler = async () => {
    if (!prevStep || !prevStep.value) return;

    await setCurrentStep(prevStep.value.current.id);
  };

  const onStepCompleteHandler = async (/* { stepId }: { stepId: UUID } */) => {
    isLoading.value = true;
    await sleep(1000);
    await fetch();
    isLoading.value = false;

    if (!currentStep.value) return;

    if (!isCourseStepAtom(currentStep.value.current)) return;

    if (currentStep.value.current.stepSessionStatus !== PlayerSessionStatus.COMPLETED) {
      courseSession.value.finishedStepsCount += 1;
      currentStep.value.current.stepSessionStatus = PlayerSessionStatus.COMPLETED;
    }
  };

  const onStepSetHandler = async ({ id }: { id: UUID }) => {
    await setCurrentStep(id);

    // sendAnalytic(step: ICourseItem) {
    //     if (!analyzedPages.includes(this.$route.name!)) return;

    //     const data = {
    //       companyId: this.companyId,
    //       playerSessionId: this.playerSessionId,
    //       stepId: this.stepId,
    //       trackSessionId: this.trackSessionId,
    //     };

    //     this.$di.tmt.sendEvent('send', 'click', step.name, 'right-sidebar-link', 'internalLink', data);
    //   },
  };

  const onNewVersionLoaded = async () => {
    await fetch();
  };

  fetch();

  return {
    isLoading: readonly(isLoading),
    isError: readonly(isError),
    isCurrentStepCompleted: readonly(isCurrentStepCompleted),
    isCurrentStepLoading,
    isNextStepAvailable: readonly(isNextStepAvailable),
    isPrevStepAvailable: readonly(isPrevStepAvailable),
    isLinearFlow: readonly(isLinearFlow),
    course: readonly(course),
    steps: readonly(stepsState),
    courseSession: readonly(courseSession),
    courseCompletionRate: readonly(courseCompletionRate),
    assignmentFinishData: readonly(assignmentFinishData),
    currentStepId,
    currentStep,
    prevStep,
    nextStep,
    fetch,
    onStepNextHandler,
    onStepPrevHandler,
    onStepCompleteHandler,
    onStepSetHandler,
    onNewVersionLoaded,
  };
};
