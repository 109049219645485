import { INodeCurrent, ITree } from './types';

export function dfs<
  T extends INodeCurrent
>(tree: ITree<T> | undefined, walker: (node: ITree<T>) => boolean) {
  if (!tree) return;

  const queue: ITree<T>[] = [tree];

  // eslint-disable-next-line no-cond-assign
  while (queue.length) {
    const node = queue.shift()!;

    const shouldContinue: boolean = walker(node);

    if (!shouldContinue) return;

    const children = node.children ?? [];

    queue.unshift(...children);
  }
}
