import { INodeCurrent, ITree } from './types';

export const isDepth = <
  T extends INodeCurrent
>(
    tree: ITree<T>,
    level: number,
  ): boolean => (tree.parent
    ? isDepth(tree.parent, level - 1)
    : level === 0);
