import { INodeCurrent, ITree } from './types';

export const make = <
  Root extends INodeCurrent,
  Children extends INodeCurrent,
  Parent extends INodeCurrent
>(
    current: Root,
    parent: ITree<Parent> | null = null,
    children: ITree<Children>[] = [],
  ) => {
  const tree: ITree<Root, Children, Parent> = {
    current,
    parent,
    children: [],
  };

  tree.children = children?.map<ITree<Children, Children, Root>>((child) => ({
    ...child,
    parent: tree,
  }));

  return tree;
};
