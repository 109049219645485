import { AtomType } from '../atom';
import {
  DateTimeISO8601, PlayerSessionId, PlayerSessionStatus, UUID, AttemptStatus,
} from '../common';
import { TrackSessionStatus } from '../track';

export interface ICourse {
  id: UUID;
  name: string;
  catalogueAtomId: UUID;
  authorId: UUID;
  ownerId: UUID;
  linearFlow: boolean;
  durationHours: number;
  durationMinutes: number;
}

export interface ICourseHierarchy {
  id: UUID;
  itemType: CourseTreeNodeType;
  children: ICourseHierarchy[];
}

export interface ICourseSession {
  trackSessionStatus: TrackSessionStatus;
  finishedStepsCount: number;
  totalStepsCount: number;
}

export interface ICourseAssignment {
  assignmentSessionId: number;
  catalogueAtomId: ICourse['catalogueAtomId'];
  startDate: DateTimeISO8601;
  finishDate: DateTimeISO8601;
}

export interface ICourseAtom {
  id: UUID;
  catalogueAtomId: UUID;
  atomContentId: UUID;
  atomType: AtomType;
  name: string;
  description: string;
  duration: number;
  source: string;
  authorId: UUID;
  ownerId: UUID;
  licenseType: string;
  stepSessionStatus: PlayerSessionStatus;
  stepSessionId: number;
  playerSessionId: PlayerSessionId;
  attemptStatus: AttemptStatus;
}

export interface ICourseSection {
  id: UUID;
  name: string;
  description: string;
  durationMinutes: number;
  durationHours: number;
  totalLessonCount: number;
  totalTestCount: number;
  sectionSessionStatus: string;
  sectionSessionId: number;
  finishedStepsCount: number;
  totalStepsCount: number;
}

export type ICourseItem = ICourseAtom | ICourseSection;

export interface ICourseSectionState extends ICourseSection {
  itemType: CourseTreeNodeType.SECTION;
  isDisabled: boolean;
  isActive: boolean;
  isOpened: boolean;
  orderNumber: number;
  progress: number;
}

export interface ICourseAtomState extends ICourseAtom {
  itemType: CourseTreeNodeType.STEP;
  isDisabled: boolean;
  isActive: boolean;
  isCompleted: boolean;
  isFailed: boolean;
  isWasted: boolean;
}

export type ICourseStepState = ICourseSectionState | ICourseAtomState;

export enum CourseTreeNodeType {
  SECTION = 'section',
  STEP = 'step',
}

export type ICourseStepEntity = ICourseItem;
