import { getFirstLeaf } from './getFirstLeaf';
import { hasChildren } from './hasChildren';
import { INodeCurrent, ITree } from './types';

export const nextSibling = <T extends INodeCurrent>(
  tree: ITree<T> | undefined,
  predicate: (node: T) => boolean = () => true,
): ITree<T> | undefined => {
  if (!tree || !tree.parent) return undefined;

  const siblings = tree.parent.children;

  const index = siblings.findIndex((child) => child === tree);

  if (index === siblings.length - 1) {
    let next = nextSibling(tree.parent, predicate);

    if (!next) {
      return undefined;
    }

    next = hasChildren(next) ? getFirstLeaf(next) : next;

    return next
      ? predicate(next.current)
        ? next
        : nextSibling(next, predicate)
      : undefined;
  }

  const next = getFirstLeaf(siblings[index + 1]);

  return next
    ? predicate(next.current)
      ? next
      : nextSibling(next, predicate)
    : undefined;
};
