import { getLastLeaf } from './getLastLeaf';
import { hasChildren } from './hasChildren';
import { INodeCurrent, ITree } from './types';

export const prevSibling = <T extends INodeCurrent>(
  tree: ITree<T> | undefined,
  predicate: (node: T) => boolean = () => true,
): ITree<T> | undefined => {
  if (!tree || !tree.parent) return undefined;

  const siblings = tree.parent.children;

  const index = siblings.findIndex((child) => child === tree);

  if (index === 0) {
    let prev = prevSibling(tree.parent, predicate);

    if (!prev) {
      return undefined;
    }

    prev = hasChildren(prev) ? getLastLeaf(prev) : prev;

    return prev
      ? predicate(prev.current)
        ? prev
        : prevSibling(prev, predicate)
      : undefined;
  }

  const prev = getLastLeaf(siblings[index - 1]);

  return prev
    ? predicate(prev.current)
      ? prev
      : prevSibling(prev, predicate)
    : undefined;
};
