import { ITree } from '@/common/tree';
import {
  ICourseAtomState, ICourseStepState, CourseTreeNodeType,
} from '../types';

export function isCourseStepAtomNode<
  Children extends ICourseStepState,
  Parent extends ICourseStepState
>(node: ITree<ICourseStepState, Children, Parent>): node is ITree<ICourseAtomState, Children, Parent> {
  return node.current.itemType === CourseTreeNodeType.STEP;
}
