import { ITreeJson } from '@/common/tree';
import { ICourseHierarchy, ICourseItem } from '../types';

interface IOptions {
  linearFlow: boolean;
  level: number;
}

const DEFAULT_OPTIONS = {
  linearFlow: false,
  level: 1,
};

export const buildCourseStepsTree = <T extends ICourseItem>(
  hierarchy: ICourseHierarchy,
  dictionary: T[],
  userOptions: Partial<IOptions> = {},
): ITreeJson<T> => {
  const options = { ...DEFAULT_OPTIONS, ...userOptions };

  const entity = dictionary.find((__entity) => __entity.id === hierarchy.id)!;

  const tree = {
    current: {
      ...entity,
      id: hierarchy.id,
      itemType: hierarchy.itemType,
      isActive: false,
      isDisabled: false,
      isCompleted: false,
      isFailed: false,
    },
    children: hierarchy.children.map((child) => buildCourseStepsTree(child, dictionary, {
      ...options,
      level: options.level + 1,
    })),
  };

  return tree;
};
